.container {
  width: 100%;
  z-index: 1;
}

.containerSection {
  max-width: 1440px;
  margin: auto;
  padding-bottom: 2rem;
  width: 100%;
}

.dots {
  display: 9999;
}

.dotsMobile {
  display: none;
}

.dutyContainer {
  display: flex;
  width: 100%;
  max-width: 1440px;
  
}

.dutyContainer img {
  width: 100%
}

.leftCol,
.rightCol {
  width: 50%;
  padding: 2rem;
}

.title {
  color: #373E42;
  font-size: 3.125rem;
  max-width: 1150px;
  margin: auto;
  padding: 3.125rem 2rem;
  text-align: center;
}

.youAreSafeBox {
  display: flex;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0.1rem;
  width: fit-content;
  position: relative;
  bottom: 4rem;
  left: -1.5rem;
  box-shadow: -9px 7px 5px -3px rgba(0, 0, 0, 0.25);;
}

.youAreSafeCheck {
  font-weight: 700;
  padding: 0.8rem 0 0 1rem;
}

.youAreSafeText {
  font-weight: 600;
  padding: 0.8rem 1.2rem 0.7rem 1rem;
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 84px;
  }

  .dots {
    display: none;
  }

  .dotsMobile {
    display: contents;
    width: 100%;
  }

  .dutyContainer {
    flex-wrap: wrap-reverse;
  }
  
  .dutyContainer img {
    width: 100%
  }

  .leftCol,
  .rightCol {
    width: 100%;
  }

  .leftCol {
    padding: 0 4rem 0 2rem;
  }

  .rightCol {
    padding: 1rem 2rem 0 2rem;
  }

  .title {
    font-size: 1.5rem;
    padding: 1rem 2rem;
  }

  .youAreSafeBox {
    left: -1rem
  }
}