.container {
  box-shadow: 0px 6px 24px #0000000f;
}

.navBackground {
  display: none;
}

.navContainer {
  background-color: #FFFFFF;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  width: 100%;

  z-index: 10;
}

.logoContainer a {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #373E42;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer a:hover svg {
  animation: rotate 1.5s 1;
  animation-delay: 0.25s;
  animation-timing-function: ease-in-out;
}

.logoContainer a svg {
  margin-right: 8px;
}

.mobileToggleContainer {
  display: none;
}

.navigationElementContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style-type: none;
  padding: 12px 0;
  margin: 0;
}

.navigationElement, 
.navigationElementActive {
  padding: 0 1rem;
  cursor: pointer;
  transition: 0.5s;
  text-decoration: none;
  color: #373E42;
  font-size: 16px;
  display: block;
  animation: reveal 0.5s 1;
}

.navigationElement div {
  border-bottom: 2px solid transparent;
  padding: 0.5rem 0;
}

.navigationElementActive div {
  font-weight: bold;
  border-bottom: 2px solid #373E42;
  padding: 0.5rem 0;
}

.navigationElement:hover div {
  border-bottom: 2px solid #373E42;
  transition: 0.5s;
}

.navigationMobile {
  display: none;
}

.navigationMobileHidden {
  display: none;
}

.navigationMobileActive {
  display: block;
  margin: 0;
  padding: 0;
  z-index: 10;
}

/* Animations */
@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes reveal {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .navigation {
    display: none;
  }

  .container {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;
  }

  .navBackground.visible {
    display: block;
    background: transparent;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    z-index: -1;
  }

  .innerShadowOff {
    box-shadow: inset 0px 0px 0px #00000000;
    transition: 1s;
  }

  .innerShadowOn {
    box-shadow: inset 0px 0px 24px #0000000f;
    transition: 1s;
  }

  .navContainer {
    width: 100%;
    padding: 1.5rem 1.5rem;
    box-sizing: border-box;
    z-index: 10;
    transition: 0.2s;
  }

  .navigationMobile {
    display: block;
    text-decoration: none;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem 1rem 1rem;
    margin: unset;

    border-radius: 0 0 8px 8px;

    box-shadow: 0px 5px 5px #0000000a;
    background-color: #FFFFFF;
  }

  .mobileToggleContainer {
    all: unset;
  }

  .mobileToggle {
    width: 24px;
    height: 16px;
    padding: 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    border: 1px solid #FFFFFF00;
    transition: 0.25s;
  }

  .mobileToggle:hover {
    border: 1px solid #00000022;
    border-radius: 5px;
  }

  .toggleLines {
    width: 100%;
    height: 2px;
    background-color: #373E42;
  }

  .navigationMobile li {
    width: 100%;
    text-align: center;
  }

  .navigationMobile li .navigationElement,
  .navigationMobile li .navigationElementActive {
    padding: 1rem 0;
    margin: unset;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .navigationMobile li .navigationElement div {
    border-bottom: 2px solid #FFFFFF00;
    display: initial;
  }

  .navigationMobile li .navigationElementActive div {
    border-bottom: 2px solid #373E42;
    display: initial;
  }
}