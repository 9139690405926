.accordionButton {
  all: unset;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  text-align: left;
  font-size: 2rem;
  font-weight: 700;
  color: #108B54;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow {
  display: flex;
}

.arrowMobile {
  display: none;
}

.arrowRotateDown {
  transform: rotate(0);
  transition: linear 0.3s;
}

.arrowRotateUp {
  transform: rotate(-180deg);
  transition: linear 0.3s;
}

.content {
  display: none;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.contentShow {
  display: flex;
  font-family: 'Roboto';
  font-size: 24px !important;
  font-weight: 400;
  text-align: justify;
  padding-left: 0.7rem;
  padding-right: 1.5rem;
  transition: all 0.6s cubic-bezier(0, 1, 0, 1);
}


@media only screen and (max-width: 768px) {
  .accordion {
    max-width: 100%;
  }
  .accordionButton {
    white-space: inherit;
    font-size: 20px;
    padding-left: -4rem;
  }
  
  .contentShow {
    font-size: 20px !important;
  }

  .arrow {
    display: none;
  }

  .arrowMobile {
    display: inline-block;
    padding-left: -4rem;
  }
}
