.sliderParallaxContainer {
  height: calc(420px * (16/9));
  height: 85vh;
  background-color: #00000044;
  overflow: hidden;
}

.sliderParallax {
  box-sizing: border-box;
  height: 100%;
  width: 500vw;
  display: flex;
  flex-direction: row;
  transition: 1s;
}

.sliderParallax div {
  /* width: calc(100% / 5); */
  flex: 1 1;
  height: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.imageSlide div {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  width: 100%;
  transform: scale(1.5);
  transition: 1s;
}

.imageSlideActive div {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  width: 100%;
  transform: scale(1);
  transition: 2s;
}

.imageSlide {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  width: 100%;
  transform: translate(50wv, 0);
  transition: 1.5s;
}

.imageSlideActive {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  width: 100%;
  transform: translate(0, 0);
  transition: 1s;
}

/* BUTTONS */
.btnPreviews, .btnNext {
  background-color: transparent;
  color: #FFFFFFaa;
  font-size: 2rem;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 3s;
  position: absolute;
  top: 45%;
  transform: translate(0, -50%);
  cursor: pointer;
}

.btnPreviews {
  left: 10px;
}

.btnNext {
  right: 10px;
}

.btnNext svg {
  transform: rotateZ(180deg);
}

/* slider content */
.sliderContent {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30%;
  text-align: right;
  padding: 120px 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.sliderTitle {
  color: #FFFFFF;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  text-shadow: 0px 4px 4px #00000044;
  animation: fadeIn 2.5s;
}

.sliderSubtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 64px;
  text-align: right;
  letter-spacing: -0.02em;
  text-shadow: 0px 4px 4px #00000044;
  margin-bottom: 1.5rem;
  color: #FFFFFF;
  animation: fadeIn 3s;
}

.sliderButton {
  font-family: 'Roboto';
  font-weight: bold;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 156px;
  height: 44px;
  border: 1px solid #FFFFFF;
  border-radius: 32px;
  background-color: transparent;
  color: #FFFFFF;
  font-size: 14px;
  text-shadow: 0px 4px 4px #00000044;
  animation: fadeIn 4s;
  cursor: pointer;
}

.dotContainer {
  padding: 1rem;
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

/* Dots */
.slideContainer .dot {
  all: unset;
  width: 14px;
  height: 14px;
  margin: 5px;
  border-radius: 50%;
  background-color: #B3B3B3;
  transition: 0.2s;
  position: relative;
  cursor: pointer;
}

.slideContainer .activeDot {
  transform: translateY(-2px);
  all: unset;
  width: 14px;
  height: 14px;
  margin: 5px;
  border-radius: 50%;
  background-color: green;
  transition: 0.2s;
  position: relative;
  cursor: pointer;
  bottom: 2px;
}

/* Slider Content */
.sliderContent {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30%;
  text-align: right;
  padding: 120px 64px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.sliderTitle {
  color: #FFFFFF;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  text-shadow: 0px 4px 4px #00000044;
  animation: fadeIn 2.5s;
}

.sliderSubtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 64px;

  text-align: right;
  letter-spacing: -0.02em;
  text-shadow: 0px 4px 4px #00000044;

  margin-bottom: 1.5rem;

  color: #FFFFFF;
  animation: fadeIn 3s;
}

.sliderButton {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 156px;
  height: 44px;
  border: 1px solid #FFFFFF;
  border-radius: 32px;
  background-color: transparent;
  color: #FFFFFF;
  font-size: 14px;
  text-shadow: 0px 4px 4px #00000044;
  animation: fadeIn 4s;
}

/* Swipe area */
.swipeArea {
  display: none;
}

/* Animations */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* MOBILE */
@media only screen and (max-width: 768px) {
  .sliderParallaxContainer {
    height: calc(420px * (16/9));
    height: 85vh;
    background-color: #00000044;
    overflow: hidden;
  }

  .swipeArea {
    display: block;
    position: absolute;
    top: 10%;
    bottom: 30%;
    left: 20%;
    right: 20%;
    z-index: 1;
  }
  
  .sliderContent {
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    height: 70%;
  }

  .sliderTitle {
    font-size: 34px;
    text-align: center;
    margin-bottom: 1rem;
  }

  .sliderSubtitle {
    font-size: 18px;
    line-height: 2rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  .btnPreviews, .btnNext {
    height: 90%;
    width: 46%;
  }
  .btnPreviews svg, .btnNext svg {
    display: none;
  }

  /* Dots */
  .slideContainer .dot, .slideContainer .activeDot {
    width: 8px;
    height: 8px;
  }

  /* RECENTER EACH SLIDE ON MOBILE */
  .sliderParallax div:nth-child(1) div {
    background-position-x: 58%;
    /* transform: scale(0.5); */
  }

  .sliderParallax div:nth-child(2) div {
    background-position-x: 54%;
  }

  .sliderParallax div:nth-child(3) div {
    background-position-x: 28%;
  }

  .sliderParallax div:nth-child(4) div {
    background-position-x: 47%;
  }

  .sliderParallax div:nth-child(5) div {
    background-position-x: 44%;
  }
}

/* REMOVE MOBILE CLICK HIGHLIGHT */
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}