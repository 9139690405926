footer.footer {
  background-color: #373D42;
  width: 100%;
  max-width: 100%;
  border-top: 2px solid #74787B;
}

.container {
  width: 100%;
  max-width: 1400px;
  padding: 1rem;
  box-sizing: border-box;
}

footer .container {
  padding-top: 32px;
  padding-bottom: 32px;
  margin: auto;
}

.logoContainer {
  text-align: center;
  padding: 16px;
}

.logoContainer svg:hover {
  animation: rotate 1.5s 1;
  animation-timing-function: ease-in-out;
}

.footer nav ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
}

.footer nav ul li {
  padding: 0 24px;
}

.footer nav ul li a {
  color: #C5CCD1;
  font-family: 'Jost';
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  transition: 0.2s;
}

.footer nav ul li a:hover {
  color: #FFFFFF;
}

.footer .footerCredits p {
  color: #AEB7BD;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  padding: 16px 16px 0px 16px;
  text-align: center;
}

/* Animations */
@keyframes rotate {
  0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

/* MOBILE */
@media only screen and (max-width: 768px) {
  .logoContainer svg {
    transform: scale(1.111);
  }
  .footer nav ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer nav ul li {
    padding: 12px;
  }
}
