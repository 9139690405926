.faqContainer {
  width: 100%;
  max-width: calc(824px + 1rem);
  margin: auto;
  z-index: 1;
}

.faqTitle {
  font-family: 'Jost';
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #373E42;
}

.faqAccordionContainer {
  /* Here we will add a margin below to separate from next element */
  margin-bottom: 2rem;
}

.faqAccordion {
  margin-bottom: 1.5rem;
  padding: 2.5rem 2rem;
  background: #FFFFFF;
  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
  border-radius: 16px;

  display: flex;
  flex-direction: row;
}

.faqAccordionContent {
  width: 100%;
  padding-right: 1rem;
  position: relative;
}

.faqAccordionContent h3 {
  font-family: 'Jost';
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: #170F49;
}

.faqAccordionContent p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #5C656B;

  display: none;

  transition: 0.2s;
}

.faqAccordionContent p.visible {
  display: block;
}

.faqAccordionToggle button {
  border: none;
  /* background: #108B54; */
  color: #FFFFFF;
  font-size: 24px;

  border-radius: 8px;
  width: 42px;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.toggleOn button {
  background: #108B54;
}

.toggleOff button {
  background: #F3F7FA;
  color: #5C656B;
}

.btnViewMoreContainer {
  text-align: center;
}

.btnViewMore {
  background-color: transparent;
  border: none;
  padding: 1rem;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;

  text-align: justify;
  letter-spacing: -0.02em;
  text-decoration-line: underline;

  color: #108B54;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .faqAccordionContainer {
    padding: 2rem;
  }
}