.container {
  width: 100%;
  margin: auto;
  min-height: calc(100vh - 65px);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  box-sizing: border-box;
}

.messageContainer {
  padding: 3rem 1rem;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.messageContainer h1 {
  margin: 0;

  font-family: 'Jost';
  font-weight: 700;
  font-size: 36px;
  letter-spacing: -0.02em;

  text-align: center;

  color: #373E42;
}

.messageContainer p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 30px;
  line-height: 2rem;

  max-width: 760px;

  text-align: center;
  letter-spacing: -0.02em;

  color: #74787B;
}

.imageContainer {
  padding: 1rem;
  margin: 1rem 0;
}

.imageContainer svg {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .container {
    margin-top: 65px;
  }
  
  .messageContainer h1 {
    font-size: 32px;
  }

  .messageContainer p {
    font-size: 24px;
    line-height: 2.5rem;
    margin-bottom: 0;
  }

  .imageContainer {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}