.contactContainer {
  width: 100%;
  /* max-width: 1400px; */
  max-width: 824px;
  margin: auto;
  padding: 5rem 1rem;
  box-sizing: border-box;
}

.contactTitle {
  color: #373E42;
  font-family: 'Jost';
  font-weight: 700;
  font-size: 36px;
  /* letter-spacing: -0.02em; */
  line-height: 60px;
  text-align: center;
  margin: 0;
}

p.contactText {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #74787B;
  text-align: center;
  /* max-width: 717px; */
  padding: 1.25rem;
  margin: auto;
}

.contactForm {
  background: #FFFFFF;
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 32px;
  margin: 1.25rem 0;
}

.contactForm h3 {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #495157;
}

.contactForm input, .contactForm textarea {
  color: #373E42;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border: 1px solid #99A2A8;
  border-radius: 8px;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px ;
}

.submitContainer {
  text-align: right;
}

.submitContainer button:enabled, .submitContainer button[disabled] {
  color: #FFFFFF;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  background: #1DAD6C;
  border-radius: 32px;
  padding: 12px 31px;
  border: 2px solid #1DAD6C;
  cursor: pointer;
  transition: 0.2s;
}

.submitContainer button[disabled], .submitContainer button[disabled]:hover {
  cursor: not-allowed;
  background-color: #99A2A8;
  border: 2px solid #99A2A8;
}

.submitContainer button:enabled:hover {
  background-color: #108B54;
  border: 2px solid #108B54;
  /* box-shadow: 0px 4px 8px #00000022; */
}

/* MOBILE */
@media only screen and (max-width: 768px) {
  .contactContainer {
    padding: 30px 1rem;
  }
  .contactTitle {
    font-size: 30px; /* Better fit for design on iPhone 12 pro */
    padding: 0 16px;
    margin: 0;  
  }

  p.contactText {
    font-size: 18px; /* Better fit for design on iPhone 12 pro */
    font-weight: 400;
    line-height: 26px;
    color: #373E42;
    padding: 0 10px;
    margin-bottom: none;
  }

  .contactForm {
    padding: 12px;
  }

  .submitContainer {
    padding: 0 1rem;
  }

  .submitContainer button {
    width: 100%;
  }
}

/* Smaller screen */
@media only screen and (max-width: 375px) {
  .contactTitle {
    font-size: 7.06vw; /* Alternative font size based on screen width */
  }

  p.contactText {
    font-size: 4.5vw; /* Alternative font size based on screen width */
  }
}