.modalContainer {
  background-color: #17171788;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  animation: fadeIn 0.5s;
  animation-iteration-count: 1;
  overflow: auto;
}

.modalContainer .card {
  background-color: #FFFFFF;
  width: 100%;
  max-width: 824px;
  margin: auto;
  padding: 2.5rem 2rem;
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  box-sizing: border-box;
  animation: fadeInAndSlide 0.5s;
  animation-iteration-count: 1;
  position: relative;
}

.modalContainer .card h2 {
  font-family: 'Jost';
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #373E42;
  margin: auto;
}

.modalContainer .card p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 24px;
  line-height: 2rem;
  text-align: center;
  color: #495157;
}

.modalContainer .imageContainer {
  text-align: center;
  width: 100%;
  max-width: 462px;
  margin: 2rem auto;
}

.modalContainer .imageContainer img {
  width: 100%;
}

.modalContainer .card .closeButtonContainer {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.modalContainer .card .closeButtonContainer button {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  transition: 0.25s;
}

.modalContainer .card .closeButtonContainer button:hover {
  transform: scale(1.1);
}

/* contact form */

.contactForm h3 {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #495157;
}

.contactForm input, .contactForm textarea {
  color: #373E42;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border: 1px solid #99A2A8;
  border-radius: 8px;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px ;
}

.submitContainer {
  text-align: right;
}

.submitContainer button:enabled, .submitContainer button[disabled] {
  color: #FFFFFF;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  background: #1DAD6C;
  border-radius: 32px;
  padding: 12px 31px;
  border: 2px solid #1DAD6C;
  cursor: pointer;
  transition: 0.2s;
}

.submitContainer button[disabled], .submitContainer button[disabled]:hover {
  cursor: not-allowed;
  background-color: #99A2A8;
  border: 2px solid #99A2A8;
}

.submitContainer button:enabled:hover {
  background-color: #108B54;
  border: 2px solid #108B54;
  /* box-shadow: 0px 4px 8px #00000022; */
}

/* ANIMATION */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInAndSlide {
  0% { opacity: 0; transform: translateX(100%); }
  100% { opacity: 1; transform: translateX(0%); }
}

/* MOBILE */
@media only screen and (max-width: 768px) {
  .container {
    padding-top: 84px;
  }

  .modalContainer .card .closeButtonContainer {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}