.button {
  background-color: #108B54;
  border: none;
  border-radius: 32px;
  padding: 12px 34.5px;

  color: #FFFFFF;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;

  cursor: pointer;
}