.container {
  width: 100%;
  min-height: calc(100vh - 300px);
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.messageContainer {
  padding: 3rem 1rem;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.messageContainer h1 {
  margin: 0;

  font-family: 'Jost';
  font-weight: 700;
  font-size: 36px;
  letter-spacing: -0.02em;

  text-align: center;

  color: #373E42;
}

.messageContainer p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 30px;
  line-height: 2rem;

  max-width: 760px;

  text-align: center;
  letter-spacing: -0.02em;

  color: #74787B;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2.5rem;
}

.col {
  width: 46%;
}
