.messageContainer {
  background-color: #17171788;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10;

  animation: fadeIn 0.5s;
  animation-iteration-count: 1;
  overflow: auto;
}

.messageContainer .card {
  background-color: #FFFFFF;
  width: 100%;
  max-width: 824px;
  margin: auto;
  padding: 2rem 2rem;

  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  box-sizing: border-box;

  animation: fadeInAndSlide 1s;
  animation-iteration-count: 1;
}

.messageContainer .card h2 {
  font-family: 'Jost';
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;

  color: #373E42;
  margin: auto;
}

.messageContainer .card p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 30px;
  line-height: 2rem;
  text-align: center;

  color: #74787B;
}

.messageContainer .card p.errorColor {
  color: #E31B0C;
}

.messageContainer .card .iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2rem;
}

.messageContainer .card .buttonContainer {
  text-align: center;
}

.messageContainer .card .buttonContainer button {
  border: none;
  padding: 1.25rem 3.2rem;
  gap: 8px;

  min-width: 156px;
  min-height: 44px;
  left: 622px;
  top: 728px;

  background: #108B54;
  border-radius: 32px;

  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  color: #FFFFFF;

  cursor: pointer;
}

/* Animations */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInAndSlide {
  0% { 
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* Mobile */
@media only screen and (max-width: 768px) {
  .messageContainer .card h2 {
    /* font-size: 8vw; */
    font-size: 40px;
  }

  .messageContainer .card p {
    font-size: 1.5rem;
  }
}