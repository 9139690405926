.ctaContainer {
  background-color: #373D42;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 1rem;
}

.ctaContainer .ctaTitle {
  color: #FFFFFF;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  padding: 1rem 1rem;
  margin: 0;
}

.ctaButtonContainer {
  text-align: center;
}

.ctaContainer .ctaButton {
  border: 2px solid #FFFFFF;
  border-radius: 32px;
  padding: 1rem;
  margin: auto;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #FFFFFF;
  background-color: transparent;

  cursor: pointer;
  transition: 0.2s;
}

.ctaContainer .ctaButton:hover {
  color: #373D42;
  background-color: #FFFFFF;
}

.ctaContainer .badgesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.ctaContainer .badgesContainer a {
  margin: 0 4px;
}

@media only screen and (max-width: 768px) {
  .ctaContainer .ctaTitle {
    font-size: 26px;
    padding: 24px;
    line-height: 2rem;
  }
}