.description {
  font-family: 'Roboto';
  font-size: 24px;
  color: #373E42;
}

.leftCol,
.rightCol {
  width: 50%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftCol img {
  width: 100%;
}

.title {
  align-items: center;
  display: flex;
  font-family: 'Roboto';
  justify-content: space-between;
  padding: 13px 13px;
  text-align: left;
}

.titleBox {
  border-left: 1px solid #A4A4A4;
}

.verticalCarousel {
  display: flex;
  max-width: 1440px;
  width: 100%;
}

.verticalCarouselButton {
  all: unset;
  border-left: 2px solid transparent;
  color: #A4A4A4;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 600;
  outline: none;
  width: 100%;
}

.verticalCarouselButtonActive {
  background-color: #fff;
  border-style: none;
  border-left: 2px solid #373E42;
  color: #373E42;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 600;
  outline: none;
  width: 100%;
}

.verticalCarouselMobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .description {
    font-weight: 400;
    padding: 1rem;
  }

  .description,
  .title {
    font-size: 20px;
    text-align: center;
  }

  .title {
    font-weight: 600;
  }

  .mainContainer {
    display: none;
  }

  .verticalCarousel {
    display: none;
  }

  .verticalCarouselBox {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
  }

  .verticalCarouselBox img {
    width: 80%;
  }

  .verticalCarouselMobile {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}