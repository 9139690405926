/* HEADER */

.header {
  position: relative;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.header img {
  width: 100%;
}

.headerContent {
  /* padding-top: 137px;
  padding-bottom: 61px; */

  padding: 137px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headerContent .titleHeader {
  font-family: 'Jost';
  font-weight: 700;
  font-size: 64px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  padding: 1rem;
  margin: 0;
}

.headerContent button {
  all: unset;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;

  background-color: transparent;
  padding: 12px 51px;
  margin: 1rem;
  border: 1px solid #FFFFFF;
  border-radius: 32px;

  cursor: pointer;
}

/* MOBILE */

@media only screen and (max-width: 769px) {
  /* HEADER */

  .header {
    /* padding: 35px 0; */
    padding: 6vw 0;
  }

  .headerContent {
    /* margin-top: 48px; */
    padding-top: 0px;
    padding-bottom: 8px;
  }

  .headerContent .titleHeader {
    font-size: 8vw; /* better fit */
    padding: 1vh 1vw;
    margin: auto;
  }

  .headerContent button {
    font-size: 4vw; /* better fit */
    padding: 1vw 4vw;
    margin: 0.5rem;
    border-radius: 32px;
  }
}