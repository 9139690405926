
.mainContainer {
  width: 100%;
  z-index: 1;
}

.container {
  max-width: calc(1146px + 2rem); /* width on figma */
  margin: auto;
  box-sizing: border-box;
  padding: 0 2rem;
}

/* HEADER */

.header {
  position: relative;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.header img {
  width: 100%;
}

.headerContent {
  padding-top: 137px;
  padding-bottom: 61px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headerContent .titleHeader {
  font-family: 'Jost';
  font-weight: 700;
  font-size: 64px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  padding: 1rem;
  margin: 0;
}

.headerContent button {
  all: unset;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;

  background-color: transparent;
  padding: 12px 51px;
  margin: 1rem;
  border: 1px solid #FFFFFF;
  border-radius: 32px;

  cursor: pointer;
}

/* DOTS */
.dots {
  width: 100%;
}

.dotsMobile {
  display: none;
}

/* SECTIONS */
.sectionContainer .title h2 {
  font-family: 'Jost';
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #373E42;

  padding: 0 1rem;
  padding-bottom: 2.5rem;
  margin: 0;
}

.sectionContainer .title h3 {
  font-family: 'Jost';
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #373E42;

  padding: 0 1rem;
  margin: 0;
}

.content p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: justify;
  letter-spacing: -0.02em;

  color: #373E42;

  padding-bottom: 32px;
  margin: 0;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2.5rem;
}

.row .col {
  width: 46%;
}

.row .col img {
  width: 100%;
}

.rowQTApp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 0 1rem;
}

.rowQTApp .col img {
  width: 90%;
  padding-right: 14px;
}

.rowQTApp .colQT {
  width: 54%;
}

.colQTSection .qtTitleHeader {
  display: flex;
}

.colQTSection .qtTitleHeader > div {
  padding: 0 1.75rem;
}

.colQTSection .qtTitleHeader > svg {
  transform: scale(1.25);
  margin-top: 6px;
}

.colQTSection .qtTitleHeader div svg {
  display: none;
  /* background-color: red; */
}

.colQTSection .qtTitleHeader .qtTitle {
  font-family: 'Roboto';
  font-size: 24px;
  color: #373E42;
  letter-spacing: -0.02em;
}

.colQTSection .qtTitleHeader p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  letter-spacing: -0.02em;
  color: #373E42;
  margin: 0;
  padding-bottom: 24px;
}

/* BOX */
.rowBox {
  display: flex;
  padding: 3rem 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 3rem;
}

.rowBox .col {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
}

.rowBox .col .box {
  background-color: #FFFFFF;
  width: 100%;
  min-height: 450px;
  padding: 32px 72px;
  box-sizing: border-box;
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rowBox h1 {
  font-family: 'Jost';
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #373E42;
  /* font-size: 4vmin; */
  margin: 0;
}

.rowBox p {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #373E42;
  width: 85%;
}

.rowBox .qAndButton {
  padding: 1rem;
}

.box button {
  background: #1DAD6C;
  border-radius: 32px;
  padding: 12px 46px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: 700;
}

.scanMe {
  padding: 1rem;
}

.storeBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 280px;
}

.rowBox .col:hover .qAndButton img {
  animation: colors 4s infinite;
}

/* Animation */
@keyframes colors {
  0% {filter: drop-shadow(0px 5px 8px #00000066)}
  33% {filter: drop-shadow(-2px 20px 10px #2196F3cc)}
  66% {filter: drop-shadow(-2px 20px 10px #00c500cc)}
  100% {filter: drop-shadow(0px 5px 8px #00000066)}
}

@media only screen and (max-width: 769px) {
  .mainContainer {
    padding-top: 84px;
  }
  .container {
    padding: 0 2rem;
  }

  .headerContent {
    /* margin-top: 48px; */
    padding-top: 0px;
    padding-bottom: 8px;
  }

  .headerContent .titleHeader {
    font-size: 8vw; /* better fit */
    padding: 1vh 1vw;
  }

  .headerContent button {
    font-size: 4vw; /* better fit */
    padding: 1vw 4vw;
    margin: 0.5rem;
    border-radius: 32px;
  }

  .dots {
    display: none;
  }

  .dotsMobile {
    display: block;
  }

  .sectionContainer .title h2 {
    padding-bottom: 1.5rem;
  }

  .row {
    flex-direction: column;
    padding: 0 2rem;
    padding-bottom: 2rem;
  }

  .row .col {
    width: 100%;
    padding: 0.5rem 0;
  }

  .rowQTApp {
    flex-direction: column;
  }

  .rowQTApp .col {
    text-align: center;
  }

  .rowQTApp .col img {
    width: 90%;
    padding: 0;
  }

  .rowQTApp .colQT {
    width: 100%;
  }

  .rowQTApp .colQT h1 {
    text-align: center;
  }

  .colQTSection .qtTitleHeader div {
    /* padding-left: 50px; */
    padding: 0;
  }

  .colQTSection .qtTitleHeader p {
    /* margin-left: -50px; */
    float: left;
    padding: 0.5rem 0 2rem;
    font-size: 16px;
    line-height: 1.4rem;
  }

  .colQTSection .qtTitleHeader .qtTitle {
    padding: 0;
  }

  .colQTSection .qtTitleHeader > svg {
    display: none;
  }

  .qtTitleHeader div svg {
    height: 22px;
    float: left;
    padding: .2rem 0.5rem 0rem 0;
  }

  .rowBox {
    flex-direction: column;
  }

  .rowBox .col {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  .rowBox .col .box {
    padding: 28px 24px;
    min-height: auto;
  }

  .rowBox h1 {
    font-size: 28px;
  }
  
  .rowBox p {
    font-size: 16px;
  }

  .rowBox .qAndButton {
    padding: 0.5rem;
  }
  
  .rowBox .qAndButton img {
    width: 50%;
    min-width: 100px;
    filter: drop-shadow(0px 10px 8px #2196F3cc);
  }

  .scanMe {
    display: none;
  }

  .storeBox {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(1.1);
  }

  .storeBox svg {
    padding: 0.2rem 0;
  }

  .colQTSection .qtTitleHeader div svg {
    display: block;
  }
}